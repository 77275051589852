<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Salary Appraisal History</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                 <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="{name: 'employees'}">Employees</router-link>
                </li>
                 <li class="breadcrumb-item">
                    <router-link :to="{name:'employee-details', params:{id:$route.params.emp_id}}">{{employee_name}} [IES-{{$route.params.emp_id}}]</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Salary Appraisal History</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                responsive
            >
                <template v-slot:cell(emp_id)="row">
                    {{row.item.employee.full_name}} [IES-{{row.item.emp_id}}]
                </template>
                <template v-slot:cell(actions)="row">
                    <span class="actions">
                        <span v-b-tooltip.hover @click="openHistory(row.item)"  title="Salary Correction History" class="circle"> 
                            <font-awesome-icon 
                                icon="history" 
                                style="cursor: pointer;"
                            />
                        </span>
                    </span>
                </template>
            </b-table>
        </b-container>


        <!-- History modal -->
        <b-modal :id="history.id" centered size="xl" hide-footer>
            <template #modal-title>
               <span class="h4 font-weight-bold">{{history.title}}</span> 
            </template>
            <b-table
                show-empty
                striped
                hover
                :items="history.rows"
                :fields="history.fields"
                no-local-sorting
                responsive
            >
                <template v-slot:cell(employee)>
                    {{employee_name}} [IES-{{$route.params.emp_id}}]
                </template>
            </b-table>
        </b-modal>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            api_error: "",
            sortBy: "emp_id",
            sortDesc: false,
            fields: [
                { key: "emp_id", label:"Employee", sortable: false },
                { key: "offered_gross_per_year", sortable: false },
                { key: "offered_gross_per_month", sortable: false },
                { key: "effective_from", sortable: false,
                    formatter: value => {
                        return this.dateformat(value);
                    }
                },
                { key: "actions" }
            ],
            items: null,
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            message:"",
            employee_id: null,
            employee_name:null,
            history: {
                id: 'history-modal',
                title: 'Salary Correction History',
                fields: [
                    { key: 'employee', class: "text-nowrap" },
                    { key: "offered_gross_per_year", class: "text-nowrap" },
                    { key: "offered_gross_per_month", class: "text-nowrap" },
                    { key: "effective_from", formatter: value => {
                        return this.dateformat(value);
                    } },
                    { key: "updated_by_name", label:"Updated By", class: "text-nowrap"},
                    { key: "updated_at", label:"Updated At", class: "text-nowrap"}
                ],
                rows: []
            }
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_SALARY");
        this.getEmployeeSalaries();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        
        getEmployeeSalaries: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {
                emp_id: this.$route.params.emp_id
            };
            this.axios.get(this.$api.get_employee_salaries, query)
                .then(response => {
                    this.items = response.data.data;
                    if(response.data.data[0].employee.full_name){
                        this.employee_name= response.data.data[0].employee.full_name;
                    }
                    this.api_error = "";
                    this.updateProgressBar(true);
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },

        // customSorting: function(ctx) {
        //     if (ctx.sortBy == "" || ctx.sortBy == null) {
        //         return;
        //     }
        //     let urlBody = this.deleteEmptyKeys(this.$route.query);
        //     urlBody.order = ctx.sortDesc ? "asc" : "desc";
        //     urlBody.sort_by = ctx.sortBy;
        //     urlBody.page = 1;
        //     this.api_error = "";
        //     this.getEmployeeSalaries(urlBody)
        // },

        openHistory(item) {
            this.history.rows = JSON.parse(item.history);
            this.$root.$emit("bv::show::modal", this.history.id);
        }

    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>
